:root {

	&.dpit-builder-mode,
	&.dpit-on,
	&.dpit-bon {
		body:not(.wp-admin) #wpadminbar {
			position: static !important;
			overflow: hidden;

			&:not(.dpit-target-parent):not(.dpit-target) {
				z-index: -1 !important;
			}
		}

		margin-top: 0 !important;
		// body and html at wp-admin - height 100% fix (chrome make 100% to window.height instead of doc.height)
		height: unset;

		body.wp-admin {

			height: unset;
		}
	}

	&.dpit-on {
		&:not(.dpit-target-selecting) {

			// no interaction under shadow overlay
			*:not(.dpit-wrap *):not(.dpit-wrap):not(.dpit-target-parent):not(.dpit-target):not(.dpit-target *):not(body) {
				pointer-events: none !important;
			}
		}
	}

}

:root.dpit-modal-opened {
	#wpadminbar {
		z-index: -1 !important;
	}
}