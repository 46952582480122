.dpit-hidden {
	opacity: 0 !important;


	pointer-events: none !important;

	&--no-space {
		width: 0 !important;
		height: 0 !important;
		padding: 0 !important;
		margin: 0 !important;
	}

	&--by-vis {
		visibility: hidden !important;
	}

	&--by-disp {
		display: none !important;
	}
}

.dpit-hidden-keep-events {
	opacity: 0 !important;
	pointer-events: all !important;
}



.dpit-hidden-hard {
	display: none !important;
}