p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgba(
    var(--dpit-tooltip-tx-color),
    var(--dpit-tooltip-tx-alpha)
  ) !important;
}
blockquote::before {
  color: rgb(var(--accent-color, var(--dpit-uac-prim-bg))) !important;
}
