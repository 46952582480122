.dpit-target,
tr.dpit-target>td,
tr.dpit-target>th {
	position: relative;
	@include z-index(2, true);
}

.dpit-target {
	pointer-events: all !important;


	&--block-click {
		&:not(.dpit-target--click) {
			cursor: not-allowed !important;

			a {
				cursor: not-allowed !important;
			}
		}

		&a:not(.dpit-target--click) {
			cursor: not-allowed !important;
		}
	}



	&--click {
		cursor: pointer !important;
		transition: opacity $transition-time-fast ease-in-out !important;
	}

	&--hover {
		transition: opacity $transition-time-fast ease-in-out !important;
	}
}

/*
tr.dpit-target>td,
tr.dpit-target>th {
	position: relative;
}*/

.dpit-target-parent {
	z-index: auto !important;

	&--z-idx-fix {
		@include z-index(-1, true);
		pointer-events: none !important;
	}
}



.dpit-target-dummy {
	position: fixed;
	height: 0;
	width: 0;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}