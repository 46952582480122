:root {
	--dpit-unit: 0.51px;
	--accent-color: 29, 97, 149;
	--accent-contrast-color: 255, 255, 255;
	--accent-color-gentle: 150, 150, 150;
	--accent-contrast-inv-color: 51, 51, 51;
	--accent-contrast-color-gentle: 204, 204, 204;
	--accent-contrast-inv-color-gentle: 51, 51, 51;
	--dpit-tip-content-alpha: 1;

	--highlight-border-width: 4px;
	--highlight-shadow-spread: 200vw;

	--dpit-min-tooltip-width: unit(750);

	--tooltip-border-radius: 2px;
	--highlight-border-radius: 2px;
	--button-border-radius: 0;

	--bullet-anim-size: 1.65vw; // it is half size
	--bullet-anim-gap: 1.5vw;
	--bullet-anim-border-radius: 50%;


	--btn_font: inherit;


	@include atXXXS {
		--dpit-unit: 0.11vw;
	}
}