@mixin primaryFont($weight:500, $color: false, $size: false, $lineHeight: false, $letterSpacing: false, $align: false, $fontOverride: false) {
	font-family: var(--btn_font);

	@if $fontOverride {
		font-family: $fontOverride;
	}

	@if $weight {
		font-weight: $weight;
	}

	@if $color {
		color: $color;
	}

	@if $size {
		font-size: $size;
	}

	@if $lineHeight {
		line-height: $lineHeight;
	}

	@if $letterSpacing {
		letter-spacing: $letterSpacing;
	}

	@if $align {
		text-align: $align;
	}
}

@mixin primaryFontImportant($weight:500, $color: false, $size: false, $lineHeight: false, $letterSpacing: false, $align: false, $fontOverride: false) {
	font-family: var(--btn_font) !important;

	@if $fontOverride {
		font-family: $fontOverride !important;
	}

	@if $weight {
		font-weight: $weight !important;
	}

	@if $color {
		color: $color !important;
	}

	@if $size {
		font-size: $size !important;
	}

	@if $lineHeight {
		line-height: $lineHeight !important;
	}

	@if $letterSpacing {
		letter-spacing: $letterSpacing !important;
	}

	@if $align {
		text-align: $align !important;
	}
}

@mixin activeTransitionSlideLeft {
	display: none;

	&--active {
		display: block;
		opacity: 1;
		transform: translateX(0);
		pointer-events: all;
	}

	&--in-transition {
		opacity: 0;
		transform: translateX(100%);
		pointer-events: none;
	}
}

@mixin activeTransitionSlideDown {
	display: none;

	&--active {
		display: block;
		opacity: 1;
		transform: translateY(0);
		pointer-events: all;
	}

	&--in-transition {
		opacity: 0;
		transform: translateY(100%);
		pointer-events: none;
	}
}

@mixin activeTransitionFade($display: block) {
	display: none;

	&--active {
		display: $display;
		opacity: 1;
		pointer-events: all;
	}

	&--in-transition {
		opacity: 0;
		pointer-events: none;
	}
}

@mixin panelControlItemHovFoc($transitionTime: 0.3s) {
	opacity: 0.95;
	@include transitionFast(opacity);

	&:hover {
		opacity: 1;
	}

	&:focus,
	&--is-focused {
		opacity: 1;
	}
}

@mixin dpitBoxShadowAlfa($alpha: 0.25, $blur: 4px, $spread: 0px, $inset: false) {
	box-shadow: 1px 1px $blur $spread rgba(var(--dpit-shadow-color), $alpha);

	@if $inset {
		box-shadow: inset 1px 1px $blur $spread rgba(var(--dpit-shadow-color), $alpha);
	}
}

@mixin dpitBoxShadow($blur: 4px, $spread: 0px, $inset: false) {
	box-shadow: 1px 1px $blur $spread rgba(var(--dpit-shadow-color), var(--dpit-shadow-alpha));

	@if $inset {
		box-shadow: inset 1px 1px $blur $spread rgba(var(--dpit-shadow-color), var(--dpit-shadow-alpha));
	}
}

@mixin dpitBoxShadowOff0($blur: 4px, $spread: 0px, $inset: false) {
	box-shadow: 0 0 $blur $spread rgba(var(--dpit-shadow-color), var(--dpit-shadow-alpha));

	@if $inset {
		box-shadow: inset 0 0 $blur $spread rgba(var(--dpit-shadow-color), var(--dpit-shadow-alpha));
	}
}

@mixin dpitBoxShadowOffM($blur: 4px, $spread: 0px, $inset: false) {
	box-shadow: 2px 2px $blur $spread rgba(var(--dpit-shadow-color), var(--dpit-shadow-alpha));

	@if $inset {
		box-shadow: inset 2px 2px $blur $spread rgba(var(--dpit-shadow-color), var(--dpit-shadow-alpha));
	}
}

@mixin dpitBoxShadowOffL($blur: 4px, $spread: 0px, $inset: false) {
	box-shadow: 3px 2px $blur $spread rgba(var(--dpit-shadow-color), var(--dpit-shadow-alpha));

	@if $inset {
		box-shadow: inset 3px 2px $blur $spread rgba(var(--dpit-shadow-color), var(--dpit-shadow-alpha));
	}
}

@function is-important($important) {
	@return #{if($important, '!important', '')};
}



@mixin pullOutPanelPadding($top, $bottom) {
	padding: #{$top} 35px #{$bottom};

	@include atXS {
		padding: #{$top} 25px #{$bottom};
	}

	@include atXXS {
		padding: #{$top} 20px #{$bottom};
	}
}

@mixin transition($property: all, $duration: $transition-time-fast, $important: false, $time-function: ease-in) {
	transition: $property $duration $time-function is-important($important);
}

@mixin transitionMulti($duration, $time-function, $property...) {
	@include transition(all, $duration, false, $time-function);
	transition-property: $property;
}

@mixin transitionMultiFast($property...) {
	@include transition();
	transition-property: $property;
}

@mixin transitionMultiFaster($property...) {
	@include transition(all, $transition-time-faster);
	transition-property: $property;
}

@mixin transitionMultiFasterX($property...) {
	@include transition(all, $transition-time-faster-x);
	transition-property: $property;
}

@mixin transitionFast($property: all, $important: false, $time-function: ease-in) {
	@include transition($property, $transition-time-fast, $important, $time-function);
}

@mixin transitionFaster($property: all, $important: false, $time-function: ease-in) {
	@include transition($property, $transition-time-faster, $important, $time-function);
}

@mixin transitionFasterX($property: all, $important: false, $time-function: ease-in) {
	@include transition($property, $transition-time-faster-x, $important, $time-function);
}


@mixin z-index($increment: 0, $important: false) {
	z-index: calc(var(--dp-z-index-base) + $increment) is-important($important);
}

@function unit($size: 1, $add: 0px) {
	@if ($add==0px) {
		@return calc(var(--dpit-unit) * $size);
	}

	@else {
		@return calc(var(--dpit-unit) * $size + $add);
	}
}

@function unitNeg($size: 1, $add: 0px) {
	@if ($add==0px) {
		@return calc(-1 * var(--dpit-unit) * $size);
	}

	@else {
		@return calc(-1 * var(--dpit-unit) * $size + $add);
	}
}

@mixin atXXL {
	@media (min-width: 1301px) {
		@content;
	}
}

@mixin atXL {
	@media (max-width: 1300px) {
		@content;
	}
}

@mixin atL {
	@media (max-width: 1200px) {
		@content;
	}
}

@mixin atM {
	@media (max-width: 1000px) {
		@content;
	}
}

@mixin atMMin {
	@media (min-width: 1001px) {
		@content;
	}
}


@mixin atS {
	@media (max-width: 800px) {
		@content;
	}
}

@mixin atXS {
	@media (max-width: 600px) {
		@content;
	}
}


@mixin atXXS {
	@media (max-width: 480px) {
		@content;
	}
}

@mixin atXXXS {
	@media (max-width: 400px) {
		@content;
	}
}


@mixin atTouchDev {
	@media (hover: none) {
		@content;
	}
}

@mixin atHoverAbleDev {
	@media (hover: hover) {
		@content;
	}
}