@import '../../../includes/assets/css/consts';
@import '../../../includes/assets/css/modules/dpit-hidden';
@import '../../../includes/assets/css/modules/dpit-anim-rb';
@import './base/variables.scss';


:root {

	// full screen animation
	--bullet-anim-color: var(--accent-color-gentle);
	--bullet-anim-on-color: var(--accent-contrast-color);

	// highlight
	--dpit-highlight-border-color: var(--accent-contrast-color);

	// tooltip
	--dpit-tooltip-link-color: var(--accent-color);
	--dpit-tooltip-tx-color: var(--accent-contrast-inv-color);
	--dpit-tooltip-tx-alpha: 1;
	--dpit-tooltip-bg-color: var(--accent-contrast-color);

	// overlay
	--dpit-overlay-color: var(--accent-contrast-inv-color);

	// number
	--dpit-number-tx-color: var(--accent-contrast-color);
	--dpit-number-bg-color: var(--accent-color);
	--dpit-number-border-color: var(--dpit-tooltip-bg-color);



	// buttons
	--dpit-btn-tx-color: var(--accent-contrast-inv-color);
	--dpit-btn-on-tx-color: var(--accent-contrast-color);

	--dpit-btn-bg-color: var(--accent-color-gentle-x);
	--dpit-btn-on-bg-color: var(--accent-color);


	// navig bullets
	--dpit-bullet-bg-color: var(--accent-color);
	--dpit-bullet-on-bg-color: var(--accent-color);
	--dpit-bullet-on-tx-color: var(--accent-contrast-color);
	--dpit-bullet-tx-color: var(--accent-contrast-inv-color);

	//progress
	--dpit-progress-front-color: var(--accent-color-gentle);
	--dpit-progress-bg-color: var(--accent-contrast-inv-color);

	// scrollbar
	--dpit-scrollbar-bg-color: var(--accent-contrast-inv-color-gentle);

	&.dpit-light-accent {

		// tooltip
		--dpit-tooltip-tx-color: var(--accent-contrast-inv-color);
		--dpit-tooltip-tx-alpha: 0.95;

		// overlay
		--dpit-overlay-color: var(--accent-contrast-inv-color-gentle);

		// navig bullets
		--bullet-anim-color: var(--accent-contrast-color);
		--bullet-anim-on-color: var(--accent-color);

		// buttons




	}
}



// leave default for mobiles
@include atHoverAbleDev {
	.dpit-scrollbar {
		scrollbar-width: thin;
		scrollbar-color: rgba(var(--dpit-scrollbar-bg-color), 0.5) rgba(var(--dpit-scrollbar-bg-color), 0.1);

		&::-webkit-scrollbar {
			margin: 8%;
			height: unit(16);
			width: unit(16);
		}

		&::-webkit-scrollbar-track {
			border-radius: unit(8);
			background-color: rgba(var(--dpit-scrollbar-bg-color), 0.1);
		}

		&::-webkit-scrollbar-thumb {
			border-radius: unit(8);
			background-color: rgba(var(--dpit-scrollbar-bg-color), 0.5);
		}
	}
}



.dpit-wrap {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	pointer-events: none;
	box-sizing: border-box;
	height: 100%;
	width: 100%;
	overflow: hidden;

}

.dpit-flier {
	position: absolute;
	pointer-events: none;
}

.dpit-overlay-container {

	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include z-index(-2);
	pointer-events: none;
	transition: opacity $transition-time-med ease-in;

	&--above {
		@include z-index(7);
	}
}

.dpit-overlay {
	width: 100%;
	height: 100%;
	background-color: rgb(var(--dpit-overlay-color));
	transition: background-color $transition-time-fast ease-in, opacity $transition-time-slow ease-in;
	opacity: var(--overlay-opacity);
}

.dpit-highlight {
	box-sizing: border-box;
	position: absolute;
	border: var(--highlight-border-width) solid rgba(var(--dpit-highlight-border-color), 0.9);
	border-radius: var(--highlight-border-radius);
	max-width: 100%;
	pointer-events: none;
	overflow: hidden;

	background-color: transparent !important;
	box-shadow: 0 0 0 var(--highlight-shadow-spread) rgba(var(--dpit-overlay-color), var(--overlay-opacity));
	transition: opacity $transition-time-slow ease-in;

	@include z-index(3, true);

	&--over-left {
		border-left-width: 0;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	&--over-right {
		border-right-width: 0;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	&--over-top {
		border-top-width: 0;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	&--over-bottom {
		border-bottom-width: 0;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	&--no-overlay {
		box-shadow: none;
	}

	&--hidden,
	&--dummy {
		border: none !important;
		width: 0 !important;
		height: 0 !important;
	}
}


.dpit-anim-rb {
	--bullet-anim-off-alpha: 0.8;

	&--in-highlight {
		--bullet-anim-size: 3%;
		--bullet-anim-gap: 3%; // it is half size
		--bullet-anim-color: var(--accent-contrast-inv-color);
		--bullet-anim-on-color: var(--accent-color);
		width: 100%;
		background-color: rgba(var(--accent-contrast-color-gentle), 0.95);
		transition: opacity $transition-time-med ease-out, background-color $transition-time-med ease-out;

		.dpit-anim-rb__wrap {
			position: relative;
			width: 100%;
			align-items: center;
			justify-content: center;
			max-width: 75%;
			max-height: 100%;
			overflow: hidden;

			div {
				min-width: 10px;
				min-height: 10px;
			}
		}
	}
}




.dpit-disable-interaction {
	@include z-index(3, true);
	position: absolute;
	opacity: 0;
	pointer-events: all;
	cursor: not-allowed;
}



.dpit-tooltip {
	position: absolute;
	pointer-events: all;
	padding: unit(35) unit(40) unit(20);
	background-color: rgb(var(--dpit-tooltip-bg-color));
	min-width: var(--dpit-min-tooltip-width);
	max-width: calc(95vw - unit(80));
	border-radius: var(--tooltip-border-radius);


	.dpit-tooltip-text {
		color: rgb(var(--dpit-tooltip-tx-color));
		white-space: pre-wrap;
		overflow-wrap: break-word;
		margin-bottom: var(--p_mb);
		overflow-y: auto;
		letter-spacing: .035em;

		&--wsn {
			white-space: normal;
		}

		&--cs {
			@import './../../../includes/assets/css/editor-content';

			a {
				color: rgb(var(--dpit-tooltip-link-color))
			}
		}

		@import './../../../includes/assets/css/editor-content-color';

		*:last-child {
			margin-bottom: 0 !important;
		}


		&::after {
			content: "";
			clear: both;
			display: table;
		}

		&.dpit-has-scrollbar {
			padding-right: unit(20);
		}
	}

	@include atXXXS {
		max-width: calc(95vw - unit(20));
	}

	&--center {
		background-color: rgba(var(--dpit-tooltip-bg-color), 0.97);
		border: 3px solid rgba(var(--accent-color-gentle), 1);
		box-shadow: 0 0 3px 2px rgba($text-color-dark, 0.71);
	}

}

.dpit-tooltip-ref {
	position: relative;
	pointer-events: none;
	@include z-index(4);
	background-color: transparent;
	@include transitionMultiFasterX(opacity);
}

.dpit-tooltip-radius-padding {
	padding-top: calc(var(--tooltip-border-radius) / 8);
	padding-bottom: calc(var(--tooltip-border-radius) / 8);
	padding-left: calc(var(--tooltip-border-radius) / 4);
	padding-right: calc(var(--tooltip-border-radius) / 4);
}



.dpit-arrow {
	border: unit(35) solid transparent;
	position: absolute;

	&--top {
		border-bottom-color: rgb(var(--dpit-tooltip-bg-color));
	}

	&--right {
		border-left-color: rgb(var(--dpit-tooltip-bg-color));
	}

	&--bottom {
		border-top-color: rgb(var(--dpit-tooltip-bg-color));
	}

	&--left {
		border-right-color: rgb(var(--dpit-tooltip-bg-color));
	}
}


.dpit-number {

	--dpit-btn-on-bg-alpha: 1;

	&--on-tooltip {
		--dpit-btn-on-bg-alpha: 0.95;
	}

	border-radius: 50%;
	background-color: rgb(var(--dpit-number-border-color));
	position: absolute;
	top: 0px;
	left: 0px;
	width: unit(calc(105));
	height: unit(calc(105));
	display: flex;
	justify-content: center;
	align-items: center;
	transition: opacity $transition-time-faster ease-in,
	transform $transition-time-faster-x ease-in;
	font-family: var(--btn_font);


	&__text {
		border-radius: 50%;
		width: calc(100% - 6px);
		height: calc(100% - 6px);
		background-color: rgba(var(--dpit-number-bg-color), var(--dpit-btn-on-bg-alpha));
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: unit(calc(41));
		font-weight: 600;
		line-height: 1;
		color: rgb(var(--dpit-number-tx-color));

		cursor: default;

		@include atS {
			width: calc(100% - 4px);
			height: calc(100% - 4px);
			font-size: unit(calc(39));
		}
	}

	&--hidden {
		opacity: 0 !important;
		//transform: scale(0.5);
		pointer-events: none !important;
	}


	&--over-right {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right-width: 0;
	}

	&--over-left {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-left-width: 0;
	}

	&--ba {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		border-bottom-width: 0;
	}

	&--ta {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-top-width: 0;
	}

	&--2dig {
		.dpit-number__text {
			font-size: unit(calc(39));

			@include atS {
				font-size: unit(calc(35));
			}
		}
	}

	@include atS {
		width: unit(calc(95));
		height: unit(calc(95));
	}
}



.dpit-checkbox-label {
	margin-top: unit(15);
	display: flex;
	align-items: center;
	color: rgb(var(--dpit-tooltip-tx-color));
	font-size: unit(32);
	line-height: 1.2;

	.dpit-checkbox {
		margin-right: unit(15);
		width: unit(34);
		height: unit(34);
	}

	@include atTouchDev {
		margin-top: unit(30);
		margin-bottom: unit(10);

		.dpit-checkbox {
			width: unit(45);
			height: unit(45);
		}
	}
}




.dpit-tooltip.disabled {
	pointer-events: none;
}

.dpit-main-controls {
	padding-top: unit(30);
	padding-bottom: unit(7);
}

.dpit-tooltip-buttons {
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-end;
}


.dpit-button {
	position: relative;
	overflow: visible;
	background-color: rgb(var(--dpit-btn-bg-color));
	color: rgb(var(--dpit-btn-tx-color));
	display: flex;
	align-items: center;
	justify-content: center;
	padding: unit(21) unit(30);
	margin: 0;
	letter-spacing: unit(1);
	font-size: unit(35);
	text-transform: uppercase;
	font-weight: 600;
	//letter-spacing: .05em;
	line-height: 1.5;
	white-space: nowrap;
	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;
	@include transitionMulti($transition-time-faster-x, ease-in, background-color, color, transform);
	border-radius: var(--button-border-radius);



	outline: none;

	@include atXXXS {
		letter-spacing: normal;
		min-width: unset;
	}

	&:not(.dpit-disabled) {

		&:focus {
			color: rgb(var(--dpit-btn-on-tx-color));
			background-color: rgb(var(--dpit-btn-on-bg-color), 0.95);

		}

		//@include atHoverAbleDev {

		&:active,
		&:hover,
		&.dpit-hovered {
			color: rgb(var(--dpit-btn-on-tx-color));
			background-color: rgb(var(--dpit-btn-on-bg-color));
		}

		&:active {
			transform: scale(0.99);
			box-shadow: 0px 0px 2px 0px rgb(var(--dpit-btn-bg-color));
		}

		//}
	}

	&__text {
		font-family: var(--btn_font);
	}

	&__spacer {
		min-width: unit(5);
	}

	&__ico {
		display: block;
		width: 100%;
	}

	&__ico-wrap {
		width: unit(28);

		&--symbol {
			line-height: 1;
			font-size: unit(25);

		}

	}

	&--skip {
		margin-right: auto;

		.dpit-button__ico-wrap {
			margin-right: unit(10);

			&--symbol {
				font-size: unit(32);
			}
		}
	}

	&--done {
		.dpit-button__ico-wrap {
			display: none;
		}
	}

	&--next {
		border-radius: 0 var(--button-border-radius) var(--button-border-radius) 0;

		.dpit-button__ico {
			transform: rotate(180deg);
		}

		.dpit-button__ico-wrap {
			margin-left: unit(10);

			&--symbol {
				//line-height: 1.5;
				transform: translateY(-2px);
				font-size: unit(38);
			}
		}
	}

	&--prev {
		border-radius: var(--button-border-radius) 0 0 var(--button-border-radius);

		.dpit-button__ico-wrap {
			margin-right: unit(10);

			&--symbol {
				transform: translateY(-2px);
				font-size: unit(38);
			}
		}
	}


	&--prev {
		border-radius: var(--button-border-radius) 0 0 var(--button-border-radius);
	}

	&--no-text:not(.dpit-button--done) {
		padding-left: unit(43);
		padding-right: unit(43);
		font-size: unit(43);

		.dpit-button__text {
			display: none;
		}

		.dpit-button__ico-wrap {
			margin: 0;

			&--symbol {
				font-size: unit(43);
			}
		}

		&.dpit-button--skip {
			padding-left: unit(33);
			padding-right: unit(33);

			.dpit-button__ico-wrap {
				&.dpit-button__ico-wrap--symbol {
					font-size: unit(28);
				}
			}
		}
	}


	&--full {
		margin-right: unit(0);
		border-radius: var(--button-border-radius);
		padding-left: unit(53);
		padding-right: unit(53);
	}
}

.dpit-button-pn-wrap {
	display: flex;
	margin-left: unit(30);

	&--vert {
		flex-direction: column;
		gap: unit(10);

		.dpit-button {

			&--next,
			&--prev {
				border-radius: var(--button-border-radius);
			}
		}
	}
}

.dpit-bullets {
	text-align: center;
	-webkit-user-select: none;
	user-select: none;
	overflow-y: visible;

	&--disabled {
		pointer-events: none;
	}

	&--hidden {
		opacity: 0 !important;
		pointer-events: none !important;
	}

	ul {
		margin: 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: unit(10) 0 unit(5);
		flex-wrap: nowrap;
		overflow: auto visible;
		-webkit-overflow-scrolling: touch;
		direction: ltr;
		gap: unit(10);

		&.dpit-has-scrollbar {
			margin: 0;
			padding-bottom: unit(10);
			justify-content: flex-start;
		}

		li {
			position: relative;
			list-style: none;
			margin: unit(10) 0 !important;
			padding: unit(13);
			cursor: pointer;
			text-decoration: none;
			outline: none;

			border-radius: 50%;
			background-color: rgba(var(--dpit-bullet-bg-color), 0.336);
			@include transitionMultiFaster(transform, background-color);
			flex-shrink: 0;
			display: flex;
			justify-content: center;
			align-items: center;


			//step number
			>div {
				font-size: unit(25);
				display: none;
				position: absolute;
				line-height: 1.3;
				color: rgba(var(--dpit-bullet-tx-color), 0.95);
			}

			&:hover {
				@include atHoverAbleDev {
					background-color: rgba(var(--dpit-bullet-on-bg-color), 0.737);
				}
			}

			&:active,
			&.active {
				transform: scale(1.15);
				transform-origin: center;
				cursor: default;
				background-color: rgba(var(--dpit-bullet-on-bg-color), 0.837);

				>div {
					color: rgb(var(--dpit-bullet-on-tx-color));
				}
			}

			&.not-visible-ref {
				opacity: 0.4;
				cursor: default;
			}
		}
	}

	// on touch devices - big bullets with number
	//:root:not(.dpit-is-sticky-theme) {
	@include atTouchDev {

		//padding: unit(12) 0 0;

		ul {
			padding: unit(12) unit(10) unit(15);
			gap: unit(12);

			li {
				width: unit(50);
				height: unit(50);
				margin: unit(20) 0;
				@include transitionMultiFaster(transform);

				//step number
				>div {
					display: block;
					font-family: var(--btn_font);
				}

				&:active,
				&.active {
					background-color: rgb(var(--dpit-bullet-on-bg-color), 0.9);
					margin-left: unit(5);
					margin-right: unit(5);
					transform: scale(1.3);
				}
			}
		}
	}

}




.dpit-progress {
	overflow: hidden;
	height: unit(17);
	margin: unit(30) unit(5) unit(10);
	border-radius: var(--button-border-radius);
	background-color: rgba(var(--dpit-progress-bg-color), 0.2);
}

.dpit-progress-bar {
	@include transitionFast(width);
	width: 0%;
	height: 100%;
	background-color: rgba(var(--dpit-progress-front-color), 0.822);
}



/* SECTION LIGHT ACCENT */
.dpit-light-accent {

	.dpit-number {
		&__text {
			font-weight: 900;
		}

		&--2dig {
			.dpit-number__text {
				//font-weight: 600;
			}
		}

		&--on-tooltip {
			--dpit-number-tx-color: var(--accent-contrast-color);
			--dpit-number-bg-color: var(--accent-color);
			--dpit-number-border-color: var(--dpit-tooltip-bg-color);
		}
	}

	.dpit-button {
		font-weight: 900;
	}

	.dpit-anim-rb {
		//--bullet-anim-off-alpha: 0.8;

		&__wrap {
			div {
				border: 2px solid rgba(var(--accent-contrast-color-gentle), 0.8);
			}
		}

		&--in-highlight {
			.dpit-anim-rb__wrap {
				div {
					border: 1px solid rgba(var(--accent-contrast-color), 1);
				}
			}
		}
	}

}

/* !SECTION LIGHT ACCENT */





@import './partials/dpit-target';
@import './partials/dpit-css-anim';
@import './partials/dpit-utils';
@import './partials/dpit-global-fixes';