h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--h_font) !important;
  margin-top: 0 !important;
  line-height: 1.3 !important;
  font-weight: 500;
  padding: 0 !important;
}

h1 {
  margin-bottom: var(--h1_mb) !important;
  font-size: var(--h1_font_size) !important;
}

h2 {
  margin-bottom: var(--h2_mb) !important;
  font-size: var(--h2_font_size) !important;
}

h3 {
  margin-bottom: var(--h3_mb) !important;
  font-size: var(--h3_font_size) !important;
}

h4 {
  margin-bottom: var(--h4_mb) !important;
  font-size: var(--h4_font_size) !important;
}

h5 {
  margin-bottom: var(--h5_mb) !important;
  font-size: var(--h5_font_size) !important;
}

h6 {
  margin-bottom: var(--h6_mb) !important;
  font-size: var(--h6_font_size) !important;
}

p {
  font-family: var(--p_font) !important;
  margin-bottom: var(--p_mb) !important;
  font-size: var(--p_font_size) !important;
  margin-top: 0 !important;
  line-height: 1.3 !important;
  padding: 0 !important;
}

.dpit-accent {
  color: rgb(var(--accent-color)) !important;
}

img.alignnone {
  margin: 0 1em;
}

img.alignright {
  float: right;
  margin: 0 0 1em 1em;
}

img.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}

img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

a img.alignright {
  float: right;
  margin: 0 0 1em 1em;
}

a img.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

pre {
  white-space: pre-wrap;
}

blockquote {
  position: relative;
  padding: 0.7em 0 0.2em 1.3em;
  font-size: var(--h4_font_size) !important;
  margin: 0.3em 0.5em !important;
  border: none !important;
}

blockquote::before {
  position: absolute;
  display: inline;
  top: 0;
  left: 0;
  font-family: dashicons;
  content: "\f205";
}
