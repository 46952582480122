.dpit-no-trans {
	transition: unset !important;
}

.dpit-disabled {
	pointer-events: none;
	opacity: 0.3 !important;
	cursor: default;
}

.dpit-fixed-position {
	position: fixed !important;
}


.dpit-relative-position {
	position: relative //!important;
}