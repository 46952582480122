.dpit-bullet-pulse {
	animation: dpit-anim-bullet-pulse 200ms;
}

@keyframes dpit-anim-bullet-pulse {
	0% {
		box-shadow: 0 0 2px 2px rgba(var(--accent-color), 0.2);
		transform: scale(1.2);
	}

	70% {

		box-shadow: 0 0 2px 2px rgba(var(--accent-color), 0.4);
		transform: scale(1.35);
	}

	100% {
		box-shadow: 0 0 2px 2px rgba(var(--accent-color), 0.2);
		transform: scale(1.3);
	}
}

.dpit-gelatine-pulse-short {
	animation: dpit-anim-gelatine 0.3s;
}

.dpit-gelatine-pulse {
	animation: dpit-anim-gelatine 0.5s;
}

@keyframes dpit-anim-gelatine {

	from,
	to {
		transform: scale(1, 1);
	}

	25% {
		transform: scale(0.97, 1.03);
	}

	/*50% {
		transform: scale(1.03, 0.97);
	}*/

	/*75% {
		transform: scale(0.985, 1.015);
	}*/
}

.dpit-gelatine-pulse-xl {
	animation: dpit-anim-gelatine 0.5s;
}

@keyframes dpit-anim-gelatine-xl {

	from,
	to {
		transform: scale(1, 1);
	}

	25% {
		transform: scale(0.90, 1.1);
	}

	50% {
		transform: scale(1.1, 0.90);
	}

	75% {
		transform: scale(0.925, 1.075);
	}
}

.dpit-mm-highlight {
	outline: 10px solid rgb(var(--accent-color));
}