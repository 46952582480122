.dpit-anim-rb {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	transition: opacity $transition-time-med ease-out;
	display: flex !important; // jquery fade in / out
	align-items: center;
	justify-content: center;
	cursor: wait;
	pointer-events: all;

	&--off {
		cursor: default;
		visibility: hidden;
		pointer-events: none !important;
	}

	&__wrap {
		display: flex;
		padding: var(--bullet-anim-gap, 4px);
		gap: var(--bullet-anim-gap, 4px);

		div {
			border-radius: var(--bullet-anim-border-radius, 50%);
			padding: var(--bullet-anim-size, 4px);
			transition: transform 0.2s ease-out, background-color 0.185s ease-out;
			transform-origin: center;
			background-color: rgba(var(--bullet-anim-color), var(--bullet-anim-off-alpha, 0.7));

			&.dpit-on {
				transform: scale(1.55);
				background-color: rgba(var(--bullet-anim-on-color), 0.95);

				&--1 {
					transform: scale(1.3);
					background-color: rgba(var(--bullet-anim-on-color), 0.8);
				}

				&--2 {
					transform: scale(1.2);
					background-color: rgba(var(--bullet-anim-on-color), 0.75);
				}

			}
		}

	}
}